import React from 'react';
import Home from '../components/Home';

import Layout from '../components/layout';

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
);

export default IndexPage;
